<template>
   <div class="appSotreBg">
   </div>
</template>
<script>
export default {
    name: "FailedToAppStore",
    components: {

    },
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
        setTimeout(() => {
            window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&ios_schema=scxListenStock%3A%2F%2F"
        }, 200);
    },
    created() {

    }
};
</script>
<style scoped  lang="scss" >
.appSotreBg {
    .store_button {
        width: 100px;
        height: 100px;
        background-color: cyan;
    }
}
</style>